import React, { useState, useEffect } from "react";
import "./typewriter.css";

const TypewriterEffect = () => {
  const fullText = [

    `<span class="grey-10">
    01001100 01101001 01110110 01100101 00100000 01100110 01101111 
    01110010 01100101 01110110 01100101 01110010 00100000 01110111 
    01101001 01110100 01101000 00100000 01100100 01110010 01110000 
    01100101 01110000 01000010 01100101 01100011 01101111 01101101
    01100101 00100000 01101001 01101101 01101101 01101111 01110010 
    01110100 01100001 01101100

    01100001 01101110 01110100 01101001 00101101 01100001 01100111 
    01101001 01101110 01100111

    01100110 01110101 01100011 01101011 00100000 01100110 01101111
    01110010 01100101 01110110 01100101 01110010

    01001001 00100000 01100001 01101101 00100000 01101110 01101111 
    01110100 00100000 01000010 01110010 01111001 01100001 01101110 
    00100000 01001010 01101111 01101000 01101110 01110011 01101111 
    01101110 00101110 00100000 01001001 00100000 01100001 01101101 
    00100000 01000010 01110010 01111001 01100001 01101110 00100000 
    01001010 01101111 01101000 01101110 01110011 01101111 01101110 
    00101110  
    </span>`,
    `<span class="grey-20">
     01001100 01101001 01110110 01100101 00100000 01100110 01101111 
    01110010 01100101 01110110 01100101 01110010 00100000 01110111 
    01101001 01110100 01101000 00100000 01100100 01110010 01110000 
    01100101 01110000 01000010 01100101 01100011 01101111 01101101
    01100101 00100000 01101001 01101101 01101101 01101111 01110010 
    01110100 01100001 01101100

    01100001 01101110 01110100 01101001 00101101 01100001 01100111 
    01101001 01101110 01100111

    01100110 01110101 01100011 01101011 00100000 01100110 01101111
    01110010 01100101 01110110 01100101 01110010

    01001001 00100000 01100001 01101101 00100000 01101110 01101111 
    01110100 00100000 01000010 01110010 01111001 01100001 01101110 
    00100000 01001010 01101111 01101000 01101110 01110011 01101111 
    01101110 00101110 00100000 01001001 00100000 01100001 01101101 
    00100000 01000010 01110010 01111001 01100001 01101110 00100000 
    01001010 01101111 01101000 01101110 01110011 01101111 01101110 
    00101110  
    </span>`,
    `<span class="grey-30">
    01001100 01101001 01110110 01100101 00100000 01100110 01101111 
    01110010 01100101 01110110 01100101 01110010 00100000 01110111 
    01101001 01110100 01101000 00100000 01100100 01110010 01110000 
    01100101 01110000 01000010 01100101 01100011 01101111 01101101
    01100101 00100000 01101001 01101101 01101101 01101111 01110010 
    01110100 01100001 01101100

    01100001 01101110 01110100 01101001 00101101 01100001 01100111 
    01101001 01101110 01100111

    01100110 01110101 01100011 01101011 00100000 01100110 01101111
    01110010 01100101 01110110 01100101 01110010

    01001001 00100000 01100001 01101101 00100000 01101110 01101111 
    01110100 00100000 01000010 01110010 01111001 01100001 01101110 
    00100000 01001010 01101111 01101000 01101110 01110011 01101111 
    01101110 00101110 00100000 01001001 00100000 01100001 01101101 
    00100000 01000010 01110010 01111001 01100001 01101110 00100000 
    01001010 01101111 01101000 01101110 01110011 01101111 01101110 
    00101110  
    </span>`,
    `<span class="grey-30">
    01001100 01101001 01110110 01100101 00100000 01100110 01101111 
    01110010 01100101 01110110 01100101 01110010 00100000 01110111 
    01101001 01110100 01101000 00100000 01100100 01110010 01110000 
    01100101 01110000 01000010 01100101 01100011 01101111 01101101
    01100101 00100000 01101001 01101101 01101101 01101111 01110010 
    01110100 01100001 01101100

    01100001 01101110 01110100 01101001 00101101 01100001 01100111 
    01101001 01101110 01100111

    01100110 01110101 01100011 01101011 00100000 01100110 01101111
    01110010 01100101 01110110 01100101 01110010

    01001001 00100000 01100001 01101101 00100000 01101110 01101111 
    01110100 00100000 01000010 01110010 01111001 01100001 01101110 
    00100000 01001010 01101111 01101000 01101110 01110011 01101111 
    01101110 00101110 00100000 01001001 00100000 01100001 01101101 
    00100000 01000010 01110010 01111001 01100001 01101110 00100000 
    01001010 01101111 01101000 01101110 01110011 01101111 01101110 
    00101110   
    </span>`,

 

  ];

  const [displayedText, setDisplayedText] = useState(fullText.join("\n"));
  
  useEffect(() => {
    const typewriterInterval = setInterval(() => {
      setDisplayedText((prevText) => prevText.substring(1) + prevText[0]);
    }, 10);

    return () => clearInterval(typewriterInterval);
  }, []);

  return (
    <div className="typewriter-container">
      <pre className="typewriter-text" dangerouslySetInnerHTML={{ __html: displayedText }}></pre>
    </div>
  );
};

export default TypewriterEffect;
